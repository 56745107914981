import request from '@/utils/request'


// 查询用户信息列表
export function listUser(query) {
    return request({
        url: '/biz/user/list',
        method: 'get',
        params: query
    })
}

// 查询用户信息分页
export function pageUser(query) {
    return request({
        url: '/biz/user/page',
        method: 'get',
        params: query
    })
}

// 查询用户信息详细
export function getUser(data) {
    return request({
        url: '/biz/user/detail',
        method: 'get',
        params: data
    })
}

// 新增用户信息
export function addUser(data) {
    return request({
        url: '/biz/user/add',
        method: 'post',
        data: data
    })
}

// 修改用户信息
export function updateUser(data) {
    return request({
        url: '/biz/user/edit',
        method: 'post',
        data: data
    })
}

// 删除用户信息
export function delUser(data) {
    return request({
        url: '/biz/user/delete',
        method: 'post',
        data: data
    })
}
// 导入用户信息
export function importData (data) {
    return request({
        url: '/biz/user/importData',
        method: 'post',
        data: data
    })
}
