<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>
      <a-form-model-item label='昵称' prop='nickname' >
        <a-input v-model='form.nickname' placeholder='请输入昵称' :maxLength="15"/>
      </a-form-model-item>
      <a-form-model-item label='手机号' prop='mobile' >
        <a-input v-model='form.mobile' placeholder='请输入电话' :maxLength="11"/>
      </a-form-model-item>
      <a-form-model-item label='生日' prop='birthday' >
        <a-date-picker style='width: 100%' v-model='form.birthday' format='YYYY-MM-DD'
                       allow-clear />
      </a-form-model-item>
      <a-form-model-item label='vip过期时间' prop='vipLoseTime' >
        <a-date-picker style='width: 100%' v-model='form.vipLoseTime' format='YYYY-MM-DD'
                       allow-clear />
      </a-form-model-item>
      <a-form-model-item label='用户状态' prop='status'>
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio :value="1">正常</a-radio>
          <a-radio :value="0">禁用</a-radio>
        </a-radio-group>
<!--        <a-radio-group v-model="form.status" button-style="solid">-->
<!--          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>-->
<!--        </a-radio-group>-->
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/user/user'

export default {
  name: 'CreateForm',
  props: {}
  ,
  components: {}
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      statusOptions: {
        type: Array,
        required: true
      },

      // 表单参数

      form: {
        id: null,
        mobile:null,
        nickname: null,
        sex: null,
        birthday: null,
        vipLoseTime: null,
        status: 0 ,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        nickname: [
          {
            required: true, message: '昵称不能为空', trigger: 'blur'
          }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写手机号',
            trigger: 'blur'
          }
        ],
        status: [
          {
            required: true,
            message: '用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        mobile:null,
        nickname: null,
        sex: null,
        birthday: null,
        vipLoseTime: null,
        status: 0 ,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
